import React, { useEffect, useState } from 'react';
import TitleBar from '../../../common/components/TitleBar/TitleBar';
import WalletIcon from '../common/WalletIcon/WalletIcon';
import NexBalance from '../common/NexBalance/NexBalance';
import KeyContainer from '../common/KeyContainer/KeyContainer';
import './MonetaryBase.scss';
import '../AddUser/AddNewScreen.scss';
import { I18n } from 'react-redux-i18n';
import MintBurnNex from './mintBurnNex/MintBurnNex';
import GrantListContainer from '../../containers/monetaryBase/GrantListContainer';
import WalletScreenWrapper from '../common/WalletScreen/WalletScreenWrapper';
import LoadingOverlay from 'react-loading-overlay';
import ErrorBox from '../../../common/components/ErrorBox';
import ErrorModal from './ErrorModal/ErrorModal';
import { check, copyKeyBtn } from '../common/PasteAddress/constants';

const MonetaryBaseScreen = (props) => {
    const [show, setShow] = useState(false);
    const [activeAction, setActiveAction] = useState(null);

    const [amount, setAmount] = useState(props.totalPushed);

    const [showNotifaction, setShowNotication] = useState(false);
    const [showErrorMetaMask, setShowErrorMetaMask] = useState(false);

    useEffect(() => {
        props.getW3mm();
    }, []);

    useEffect(() => {
        if (props.getW3mmErrormessage.error) {
            setShowErrorMetaMask(true);
        } else {
            props.getBalanceNexMonetaryBase();
            props.getTotalPublished();
            props.getUserAddress();
        }
    }, [props.getW3mmErrormessage]);

    useEffect(() => {
        setAmount(props.totalPushed);
    }, [props.totalPushed, props.totalPushedStatus]);

    useEffect(() => {
        setShow(props.mintBurnStatus.loading ?? false);
        setShowNotication(
            props.mintBurnStatus?.success || props.mintBurnStatus?.error ? true : false,
        );
    }, [props.mintBurnStatus]);

    useEffect(() => {
        if (showNotifaction) {
            setTimeout(() => {
                setShowNotication(false); //look for a better solution to put show => false
            }, 2500);
        }
    }, [showNotifaction]);

    const saveMintBurn = (action, value) => {
        setActiveAction(action);
        setShow(true);

        action === 'Mint' ? props.getEmitMint(value) : props.getRemoveBurn(value);
    };

    return (
        <WalletScreenWrapper
            to=''
            className='wrapper-card-monetary-base'
            classBody='monetary-base-card-body'
            classHead='monetary-base-card-head'
        >
            <div className='header-card'>
                <div style={{ marginRight: '5%' }}>
                    <div style={{ width: '45px', aspectRatio: '1/1' }}>
                        <WalletIcon />
                    </div>
                </div>
                <NexBalance
                    balance={props?.wallet?.balance}
                    style={{ fontSize: '24px', width: '100%' }}
                />
                <div style={{ width: '60%', display: 'flex', justifyContent: 'flex-end' }}>
                    <KeyContainer
                        walletKey={props?.wallet?.address}
                        iconStyleCopy={{ ...copyKeyBtn, color: '#f3f3f3' }}
                        iconStyleCopyCheck={{ ...check, color: '#f3f3f3' }}
                    />
                </div>
            </div>
            <div style={{ borderBottom: '2px solid #f3f3f3', margin: '2% 0 2% 0', width: '100%' }}>
                <div className='title-bar-container' style={{ padding: '0' }}>
                    <TitleBar
                        title={I18n.t('wallet.monetaryBase.monetaryBase')}
                        backgroundUnderline='light-green fixed'
                        titleClass='wallet-title'
                    />
                </div>
            </div>
            <div className='total-nex-card'>
                <span className='total-nex-title'>Total emitido</span>
                <NexBalance
                    balance={props?.totalPushed ?? 0}
                    style={{ fontSize: '44px', color: '#02656c' }}
                />
            </div>
            <div className='mint-burn-container'>
                <div className='padding-container'>
                    <MintBurnNex
                        action={I18n.t('wallet.monetaryBase.mint')}
                        handleClick={saveMintBurn}
                        status={props.mintBurnStatus}
                        activeAction={activeAction}
                        disableBtn={showErrorMetaMask}
                    />
                </div>
                <div className='padding-container'>
                    <MintBurnNex
                        action={I18n.t('wallet.monetaryBase.burn')}
                        handleClick={saveMintBurn}
                        status={props.mintBurnStatus}
                        activeAction={activeAction}
                        disableBtn={showErrorMetaMask}
                    />
                </div>
            </div>
            {props.mintBurnStatus !== undefined && (
                <div className='notication-container'>
                    <ErrorBox
                        show={showNotifaction}
                        className={`title-error ${
                            props.mintBurnStatus?.success
                                ? 'notification-wallet-success'
                                : props.mintBurnStatus.error
                                  ? 'notification-wallet-error'
                                  : ''
                        }`}
                        errorMessage={
                            props.mintBurnStatus.error &&
                            activeAction === I18n.t('wallet.monetaryBase.mint')
                                ? I18n.t('wallet.transaction.message.mintBurn.errorMint')
                                : props.mintBurnStatus.error &&
                                    activeAction === I18n.t('wallet.monetaryBase.burn')
                                  ? I18n.t('wallet.transaction.message.mintBurn.errorBurn')
                                  : props.mintBurnStatus.success
                                    ? I18n.t('wallet.transaction.message.mintBurn.successMintBurn')
                                    : ''
                        }
                        icon='false'
                    />
                </div>
            )}
            {!showErrorMetaMask && <GrantListContainer />}
            {show && (
                <div className='fullscreen-overlay'>
                    <LoadingOverlay
                        active={show}
                        spinner
                        text={I18n.t('wallet.monetaryBase.sendMetaMask')}
                    />
                </div>
            )}
            <ErrorModal show={showErrorMetaMask} />
        </WalletScreenWrapper>
    );
};
export default MonetaryBaseScreen;
