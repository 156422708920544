import React, { useEffect, useState } from 'react';
import Table from '../../../common/components/Table';
import './BonificationScreen.scss';
import { BonificationResult, DateType } from '../../../types';
import { I18n } from 'react-redux-i18n';
import SearchBar from '../../../common/components/SearchBar';
import DateRangePicker from '../../../common/components/DateRangePicker';
import SelectMultiple from '../../../common/components/SelectMultiple';
import Button from '../../../common/components/Button';
import Checkbox from '../../../common/components/Checkbox';
import { parseDate } from '../../../utils';
import { ConfirmationModal } from '../../../common/components/Modal';
import {
    downloadBonifications,
    useGetBonifications,
    useUpdateDelivered,
    useUpdateRejected,
} from '../../service/BonificationService';
import Loader from '../../../common/components/Loader';
import { PaginationProps } from '../../../common/components/Table/Table';
import ReactTooltip from 'react-tooltip';

const defaultStatusFilter = [
    { id: 'E', checked: false },
    { id: 'V', checked: true },
    { id: 'R', checked: false },
    { id: 'all', checked: false },
];

const BonificationScreen = () => {
    const [textToSearch, setTextToSearch] = useState<string>('');
    const [statusFilter, setStatusFilter] =
        useState<{ id: string; checked: boolean }[]>(defaultStatusFilter);
    const [deliveredSelected, setDeliveredSelected] = useState<{ id: number }[]>([]);
    const [rejectedSelected, setRejectedSelected] = useState<{ id: number }[]>([]);
    const [allDeliveredChecked, setAllDeliveredChecked] = useState<boolean>(false);
    const [allRejectedChecked, setAllRejectedChecked] = useState<boolean>(false);
    const [confirmationModalTitle, setConfirmationModalTitle] = useState<string>('');
    // filters for request
    const [page, setPage] = useState<number>(0);
    const [searchDate, setSearchDate] = useState('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const { data: bonifications, isLoading } = useGetBonifications(
        statusFilter,
        page + 1,
        textToSearch,
        startDate,
        endDate,
    );
    const {
        mutate: delivered,
        isLoading: isMakingDeliveredRequest,
        isSuccess: deliverSuccess,
    } = useUpdateDelivered();
    const {
        mutate: rejected,
        isLoading: isMakingRejectRequest,
        isSuccess: rejectSuccess,
    } = useUpdateRejected();

    useEffect(() => {}, [statusFilter, page, textToSearch, startDate, endDate]);

    const handleStatusFilter = (status: string) => {
        const newStatusFilter = statusFilter.map((s) => {
            if (status === 'all') {
                if (statusFilter.find((s) => s.id === 'all')?.checked) {
                    return { ...s, checked: false };
                }
                return { ...s, checked: true };
            }
            if (s.id === status) {
                return { ...s, checked: !s.checked };
            }
            return s;
        });
        setStatusFilter(newStatusFilter);
    };

    const handleDeliveredCheckbox = (id: number) => {
        const newDeliveredSelected = deliveredSelected.find((r) => r.id === id)
            ? deliveredSelected.filter((r) => r.id !== id)
            : [...deliveredSelected, { id }];
        setDeliveredSelected(newDeliveredSelected);
    };

    const handleAllCheckbox = (type: string) => {
        if (bonifications) {
            const notDelivered = bonifications.results.filter(
                (r) => r.estado !== 'E' && r.estado !== 'R',
            );
            if (type === 'delivered') {
                setAllDeliveredChecked(!allDeliveredChecked);
                const newDeliveredSelected = allDeliveredChecked
                    ? []
                    : notDelivered.map((r) => ({ id: r.id }));
                setDeliveredSelected(newDeliveredSelected);
            }
            if (type === 'rejected') {
                setAllRejectedChecked(!allRejectedChecked);
                const newRejectedSelected = allRejectedChecked
                    ? []
                    : notDelivered.map((r) => ({ id: r.id }));
                setRejectedSelected(newRejectedSelected);
            }
        }
    };

    const handleRejectCheckbox = (id: number) => {
        const newRejectedSelected = rejectedSelected.find((r) => r.id === id)
            ? rejectedSelected.filter((r) => r.id !== id)
            : [...rejectedSelected, { id }];
        setRejectedSelected(newRejectedSelected);
    };

    useEffect(() => {
        if (deliverSuccess) {
            setDeliveredSelected([]);
            setAllDeliveredChecked(false);
            setConfirmationModalTitle('');
        }
    }, [deliverSuccess]);

    useEffect(() => {
        if (rejectSuccess) {
            setRejectedSelected([]);
            setAllRejectedChecked(false);
            setConfirmationModalTitle('');
        }
    }, [rejectSuccess]);

    const handleReject = () => {
        rejected(rejectedSelected);
    };

    const handleDeliver = () => {
        delivered(deliveredSelected);
    };

    const handleDownload = () => {
        downloadBonifications(bonifications ? bonifications.results : []);
    };

    const renderDates = (dates: DateType[]) => {
        const getDateWithStatus = (date: DateType) => {
            return `${parseDate(new Date(date.created_at))} ${I18n.t(
                `bonifications.table.status.${date.estado}`,
            )}`;
        };

        const allDates = dates
            .map((date) => `<span>${getDateWithStatus(date)}</span><br>`)
            .join('');

        return (
            <div>
                <a data-tip={allDates} data-html={true} data-for='bonifications-date-tooltip'>
                    <span className='text'>{parseDate(new Date(dates[0].created_at))}</span>
                </a>
                <ReactTooltip
                    id='bonifications-date-tooltip'
                    backgroundColor='#ffffff'
                    effect='solid'
                    className='bonification-tooltip paragraph'
                />
            </div>
        );
    };

    const getColumns = () => {
        return [
            {
                id: 'id',
                Header: () => <h5>{I18n.t('bonifications.table.headers.id')}</h5>,
                accessor: 'id',
                Cell: ({ value }: { value: number }) => <span className='text'>{value}</span>,
            },
            {
                id: 'patientId',
                Header: () => <h5>{I18n.t('bonifications.table.headers.patientId')}</h5>,
                accessor: 'paciente.id',
                Cell: ({ value }: { value: number }) => <span className='text'>{value}</span>,
            },
            {
                id: 'os',
                Header: () => <h5>{I18n.t('bonifications.table.headers.os')}</h5>,
                accessor: 'paciente.obra_social',
                Cell: ({ value }: { value: string }) => <span className='text'>{value}</span>,
            },
            {
                id: 'dates',
                Header: () => <h5>{I18n.t('bonifications.table.headers.date')}</h5>,
                accessor: 'fechas',
                Cell: ({ value }: { value: DateType[] }) => renderDates(value),
            },
            {
                id: 'contract',
                Header: () => <h5>{I18n.t('bonifications.table.headers.contract')}</h5>,
                accessor: 'contrato.descripcion',
                Cell: ({ value }: { value: string }) => <span className='text'>{value}</span>,
            },
            {
                id: 'product',
                Header: () => <h5>{I18n.t('bonifications.table.headers.product')}</h5>,
                accessor: 'denominacion',
                Cell: ({ value }: { value: string }) => <span className='text'>{value}</span>,
            },
            {
                id: 'cicleNumber',
                Header: () => <h5>{I18n.t('bonifications.table.headers.cicle')}</h5>,
                accessor: 'ciclo',
                Cell: ({ value }: { value: number }) => <span className='text'>{value}</span>,
            },
            {
                id: 'status',
                Header: () => <h5>{I18n.t('bonifications.table.headers.status')}</h5>,
                accessor: 'estado',
                Cell: ({ value }: { value: string }) => (
                    <span className='text'>{I18n.t(`bonifications.table.status.${value}`)}</span>
                ),
                sortable: false,
            },
            {
                id: 'delivered',
                Header: () => (
                    <div className='header-checkbox'>
                        <h5>{I18n.t('bonifications.table.headers.delivered')}</h5>
                        <Checkbox
                            checked={allDeliveredChecked}
                            onChange={() => handleAllCheckbox('delivered')}
                        />
                    </div>
                ),
                accessor: 'estado',
                Cell: ({ value, row }: { value: string; row: BonificationResult }) => (
                    <div className='checkbox-cell'>
                        <Checkbox
                            disabled={value === 'E' || value === 'R'}
                            checked={!!deliveredSelected.find((r) => r.id === row.id)}
                            onChange={() => handleDeliveredCheckbox(row.id)}
                        />
                    </div>
                ),
                sortable: false,
            },
            {
                id: 'rejected',
                Header: () => (
                    <div className='header-checkbox'>
                        <h5>{I18n.t('bonifications.table.headers.rejected')}</h5>
                        <Checkbox
                            checked={allRejectedChecked}
                            onChange={() => handleAllCheckbox('rejected')}
                        />
                    </div>
                ),
                accessor: 'estado',
                Cell: ({ value, row }: { value: string; row: BonificationResult }) => (
                    <div className='checkbox-cell'>
                        <Checkbox
                            disabled={value === 'R' || value === 'E'}
                            checked={!!rejectedSelected.find((r) => r.id === row.id)}
                            onChange={() => handleRejectCheckbox(row.id)}
                        />
                    </div>
                ),
                sortable: false,
            },
        ];
    };

    const pagination: PaginationProps = {
        page: page,
        total: bonifications ? bonifications.count : 0,
        totalPages: bonifications ? bonifications.total_pages : 0,
        onPageChange: (page: number) => setPage(page),
        pagedMessageTranslation: 'bonifications.table.pagedMessage',
        pagedMessageEmptyTranslation: 'bonifications.table.pagedMessageEmpty',
    };
    return (
        <div className='bonification-screen'>
            <div className='header'>
                <div className='header-bar'>
                    <span className='title'>{I18n.t('bonifications.title')}</span>
                    <SearchBar textToSearch={textToSearch} onSearch={setTextToSearch} />
                    <DateRangePicker
                        textToSearch={searchDate}
                        allTransactions={bonifications ? bonifications.results : []}
                        searchText={(e) => setSearchDate(e)}
                        updateFilteredTransactionsByDateRange={() => {}}
                        clearDateRangeSelection={() => {
                            setStartDate('');
                            setEndDate('');
                            setPage(0);
                        }}
                        startDate={setStartDate}
                        endDate={setEndDate}
                        setPage={setPage}
                    />
                    <SelectMultiple
                        placeholder={I18n.t('bonifications.table.headers.status')}
                        values={statusFilter}
                        className='rounded'
                        onChange={(id: string) => handleStatusFilter(id)}
                        options={[
                            {
                                value: 'E',
                                label: I18n.t('bonifications.table.status.E'),
                            },
                            {
                                value: 'V',
                                label: I18n.t('bonifications.table.status.V'),
                            },
                            {
                                value: 'R',
                                label: I18n.t('bonifications.table.status.R'),
                            },
                            {
                                value: 'all',
                                label: I18n.t('bonifications.table.status.all'),
                            },
                        ]}
                    />
                    <Button
                        type='button'
                        className='primary icon-size download-button'
                        onClick={handleDownload}
                        loading={false}
                        icon={<i className='icon-cloud-download' />}
                    />
                    <div className='action-button-container'>
                        <Button
                            type='button'
                            className='third tiny'
                            onClick={() =>
                                setConfirmationModalTitle(I18n.t('bonifications.deliver'))
                            }
                            loading={false}
                            text={
                                I18n.t('bonifications.deliver') +
                                (deliveredSelected.length > 0
                                    ? ` (${deliveredSelected.length})`
                                    : '')
                            }
                            disabled={deliveredSelected.length === 0}
                        />
                        <Button
                            type='button'
                            className='error tiny'
                            onClick={() =>
                                setConfirmationModalTitle(I18n.t('bonifications.reject'))
                            }
                            loading={false}
                            text={
                                I18n.t('bonifications.reject') +
                                (rejectedSelected.length > 0 ? ` (${rejectedSelected.length})` : '')
                            }
                            disabled={rejectedSelected.length === 0}
                        />
                        <ConfirmationModal
                            show={confirmationModalTitle !== ''}
                            title={confirmationModalTitle}
                            submit={
                                confirmationModalTitle === I18n.t('bonifications.deliver')
                                    ? handleDeliver
                                    : handleReject
                            }
                            cancel={() => setConfirmationModalTitle('')}
                        >
                            <div className='modal-text-container'>
                                {isMakingDeliveredRequest || isMakingRejectRequest ? (
                                    <Loader />
                                ) : (
                                    <span className='modal-text'>
                                        {confirmationModalTitle === I18n.t('bonifications.deliver')
                                            ? I18n.t('bonifications.modal.deliverMessage', {
                                                  bonificationQty: deliveredSelected.length,
                                              })
                                            : I18n.t('bonifications.modal.rejectMessage', {
                                                  bonificationQty: rejectedSelected.length,
                                              })}
                                    </span>
                                )}
                            </div>
                        </ConfirmationModal>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <Table
                    data={bonifications ? bonifications.results : []}
                    columns={getColumns()}
                    pagination={pagination}
                    noDataText={I18n.t('bonifications.table.noRows')}
                />
            )}
        </div>
    );
};

export default BonificationScreen;
