import React from 'react';
import { I18n } from 'react-redux-i18n';
import IncidentCard from './components/IncidentCard';
import Card from '../../../common/components/Card/Card';
import {
    Patient,
    ContractsTable,
} from '../../../patient/components/EditPatientScreen/EditPatientScreen';
import BreadCrumbs from '../../../common/components/BreadCrumbs/BreadCrumbs';

const AuditIncident = ({
    patientDetail,
    auditGroupData,
    transactionStatus,
    setContractSelected,
    getProgramedDose,
    setBreadcrumbs,
    postAuditorTransactions,
    getFileToS3,
    getAuditorProgramedDose,
    userType,
}) => {
    const [isAuditPending, setIsAuditPending] = React.useState(false);
    const [patient, setPatient] = React.useState({});

    const brcrums = [
        { label: I18n.t('auditor.brcrum.transactions'), link: '/auditorTransactions' },
        { label: patientDetail.nombre, link: '/auditIncident' },
    ];

    React.useEffect(() => {
        setBreadcrumbs(brcrums);
    }, []);

    React.useEffect(() => {
        patientDetail.estado === 'P' && setIsAuditPending(true);
        setPatient({
            ...patient,
            affiliate: patientDetail._original.paciente.afiliado,
            dni: patientDetail._original.paciente.dni,
            id: patientDetail._original.paciente.paciente_id,
            name: patientDetail._original.paciente.nombre,
            birthDate: patientDetail._original.paciente.fecha_nacimiento,
            sex: patientDetail._original.paciente.sexo,
            weight: [
                {
                    effdt: null,
                    id: 1,
                    peso: patientDetail._original.paciente.peso?.toString(),
                    _id: 1,
                },
            ],
            status: patientDetail._original.paciente.activo ? 'active' : 'inactive',
            contracts: [
                {
                    contrato: patientDetail._original.contrato.id,
                    descripcion: patientDetail._original.contrato.descripcion,
                    id: patientDetail._original.paciente_contrato,
                    indicacion_descr: patientDetail._original.contrato.indicacion_descr,
                    label: patientDetail._original.contrato.descripcion,
                    subtipo: patientDetail._original.contrato.indicacion_descr,
                    tipo_contrato: patientDetail._original.contrato.tipo_contrato,
                    descripcion_tipo: patientDetail._original.contrato.tipo_contrato_descr,
                    _id: patientDetail._original.paciente_contrato,
                    evidencia: patientDetail._original.contrato.evidencia,
                    ciclo_actual: patientDetail._original.ciclo_actual,
                    activo: patientDetail._original.paciente_contrato_activo,
                },
            ],
        });
    }, [patientDetail]);

    return (
        <div className='i-container'>
            <BreadCrumbs links={brcrums} />
            <Card
                title={I18n.t('patient.editPatient.patientCard.title')}
                detail={I18n.t('patient.editPatient.patientCard.id') + ': ' + patient.id}
                className='side-padding-l'
            >
                <Patient patient={patient} />
            </Card>
            <Card title={I18n.t('contract.title')}>
                <br />
                <ContractsTable
                    contracts={patient.contracts}
                    setContractSelected={setContractSelected}
                    getProgramedDose={getProgramedDose}
                    lastWill={() => {}}
                    getFileToS3={getFileToS3}
                    userType={userType}
                    getAuditorProgramedDose={getAuditorProgramedDose}
                />
            </Card>
            {/* XXX: MOCKUP, DELETE AFTER */}
            <Card className='instructions' title={I18n.t('auditIncident.instructions')}>
                <p>
                    • “Debe auditar las evidencias suministradas para efectuar la bonificación de
                    unidades“
                </p>
            </Card>
            <IncidentCard
                comments={patientDetail._original.audit_historial}
                postAuditorTransactions={postAuditorTransactions}
                isPending={isAuditPending}
                setIsPending={(e) => setIsAuditPending(e)}
                auditType={patientDetail._original.tipo}
                contractType={patientDetail._original.contrato.tipo_contrato_tipo}
                transactionStatus={transactionStatus}
                auditorId={auditGroupData.id}
                id={patientDetail._original.id}
                cse={patientDetail._original.paciente.cse}
                osPublicKey={patientDetail._original.contrato.obra_social.razon_social}
                auditorName={patientDetail._original.grupo_auditor_descr}
                osName={patientDetail.obra_social.razon_social}
            />
        </div>
    );
};

export default AuditIncident;
