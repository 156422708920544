import React, { useState, useEffect } from 'react';
import './TransactionMonitorScreen.scss';
import SearchBar from '../../../common/components/SearchBar';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../common/components/Loader';
import ReactTable from 'react-table';
import TablePagination from '../../../common/components/TablePagination';
import Button from '../../../common/components/Button';
import Checkbox from '../../../common/components/Checkbox';
import SelectMultiple from '../../../common/components/SelectMultiple';
import { ConfirmationModal } from '../../../common/components/Modal';
import Swal from 'sweetalert2';
import TransactionsSuccessModal from '../TransactionsSuccessModal';
import TransactionsErrorModal from '../TransactionsErrorModal';
import { parseDate } from '../../../utils';

const defaultStatusSelected = [
    { id: 'all', checked: false },
    { id: 'Fallo Envio', checked: true },
    { id: 'Enviado', checked: false },
    { id: 'Recibido', checked: false },
];

const sendDataToSAPResultsInitialState = {
    allSuccessful: false,
    successfulTotal: 0,
    errorTotal: 0,
    errors: [],
};

const TransactionMonitorScreen = (props) => {
    const [textToSearch, setTextToSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState(defaultStatusSelected);
    const [transactionsSelected, setTransactionsSelected] = useState([]);

    const [sendDataToSAPResults, setSendDataToSAPResults] = useState(
        sendDataToSAPResultsInitialState,
    );

    const [showConfirmGrantAddressesModal, setShowConfirmGrantAddressesModal] = useState(false);

    useEffect(() => {
        const allAreSelected = allFiltersAreSelected();
        const allAreDeselected = allFiltersAreDeselected();
        const isTheAllOptionChecked = statusFilter.filter((status) => status.id === 'all')[0]
            .checked;

        // this is done to auto check the 'all' checkbox if all the other options are selected (and vise versa)
        if (
            (allAreSelected && !isTheAllOptionChecked) ||
            (allAreDeselected && isTheAllOptionChecked)
        ) {
            setStatusFilter(
                statusFilter.map((status) => {
                    if (status.id === 'all') return { ...status, checked: !isTheAllOptionChecked };
                    return status;
                }),
            );
        }

        const statusSelectedArray = statusFilter
            .filter((status) => status.id !== 'all' && status.checked)
            .map((status) => status.id);
        props.getTransactions({ statusFilter });
        props.updateFilterValues({ status: statusSelectedArray });
    }, [statusFilter]);

    /*
     * Maps the transaction payment results (quantity of success, of errors, and the errors strings).
     * */
    const mapResults = (transactions) => {
        let transactionsErrored = transactions.filter((a) => a.errors.length !== 0);

        if (transactionsErrored.length === 0)
            return { ...sendDataToSAPResultsInitialState, allSuccessful: true };

        let transactionsSuccessfulCount = transactions.length - transactionsErrored.length;

        const mapTransactionErrors = (addressId, errors) =>
            errors.map((error) => `ID ${addressId} - ${error.description}`);

        return {
            allSuccessful: false,
            successfulTotal: transactionsSuccessfulCount,
            errorTotal: transactionsErrored.length,
            errors: transactionsErrored.map((a) => mapTransactionErrors(a.id, a.errors)).flat(),
        };
    };

    useEffect(() => {
        if (props.sendDataToSAPStatus.errorMessage || props.sendDataToSAPStatus.error) {
            Swal.fire({
                icon: 'error',
                title: I18n.t('nexMonitor.sendDataToSAPErrorAlert.title'),
                text: props.sendDataToSAPStatus.errorMessage,
            }).then((result) => {
                if (result.isConfirmed) {
                    props.getTransactions({ statusFilter });
                }
            });
        } else {
            if (!props.sendDataToSAPStatus.loading && props.sendDataToSAPStatus.success) {
                props.getTransactions({ statusFilter });
                setSendDataToSAPResults({
                    ...sendDataToSAPResultsInitialState,
                    allSuccessful: true,
                    successfulTotal: props.sendDataToSAPResults.length,
                });
            }
        }
    }, [props.sendDataToSAPStatus]);

    const allFiltersAreSelected = () => {
        return (
            statusFilter.filter((status) => status.id !== 'all' && status.checked).length ===
            statusFilter.length - 1
        );
    };

    const allFiltersAreDeselected = () => {
        return (
            statusFilter.filter((status) => status.id !== 'all' && !status.checked).length ===
            statusFilter.length - 1
        );
    };

    const handleStatusFilterChange = (statusId) => {
        if (statusId === 'all') {
            const allIsSelected = statusFilter.filter((status) => status.id === 'all')[0].checked;
            setStatusFilter(
                statusFilter.map((status) => ({ id: status.id, checked: !allIsSelected })),
            );
        } else {
            const otherStatus = statusFilter.filter((status) => status.id !== statusId);
            const selectedStatus = statusFilter.filter((status) => status.id === statusId)[0];
            setStatusFilter([...otherStatus, { id: statusId, checked: !selectedStatus?.checked }]);
        }
    };

    const renderCheckBox = (rowData) => {
        if (rowData.status !== 'Fallo Envio' || rowData.detail !== 'Error enviando pago a SAP')
            return null;
        return (
            <div className='action-checkbox-container'>
                <Checkbox
                    id={
                        rowData.guid
                            ? rowData.guid
                            : rowData.osId
                              ? 'os_id_' + rowData.id
                              : 'drugstore_id_' + rowData.id
                    }
                    checked={isCheckboxSelected(
                        rowData.guid
                            ? rowData.guid
                            : rowData.osId
                              ? 'os_id_' + rowData.id
                              : 'drugstore_id_' + rowData.id,
                    )}
                    onChange={handleCheckboxChange}
                    disabled={rowData.granted}
                />
            </div>
        );
    };

    const handleCheckboxChange = (checkboxId, checked) => {
        if (checkboxId === 'ALL') {
            if (checked)
                setTransactionsSelected([
                    ...props.transactions
                        .filter((a) => !a.granted)
                        .map(
                            (a) =>
                                `${
                                    a.guid
                                        ? a.guid
                                        : a.osId
                                          ? 'os_id_' + a.id
                                          : 'drugstore_id_' + a.id
                                }`,
                        ),
                    'ALL',
                ]);
            else setTransactionsSelected([]);
        } else {
            const filtered = transactionsSelected.filter((addressId) => addressId === checkboxId);
            if (checked) {
                // if the checkbox was checked and the checkbox id is not on transactionsSelected, add to it
                if (filtered.length === 0)
                    setTransactionsSelected([...transactionsSelected, `${checkboxId}`]);
            } else {
                // if the checkbox was deselected and the checkbox id is on transactionsSelected, remove from it
                if (filtered.length === 1)
                    setTransactionsSelected([
                        ...transactionsSelected.filter((addressId) => addressId !== checkboxId),
                    ]);
            }
        }
    };
    const isCheckboxSelected = (checkboxId) => {
        const filtered = transactionsSelected.filter(
            (addressId) => addressId.toString() === checkboxId.toString(),
        );
        return filtered.length === 1;
    };

    const handleReSendSapButtonClick = () => {
        if (transactionsSelected.length > 0) {
            props.resendDataToSAP(transactionsSelected);
            setTransactionsSelected([]);
        }
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'guid', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transactionMonitor.table.headers.guid')}</h5>,
                accessor: (data) => data.guid, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'status', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transactionMonitor.table.headers.status')}</h5>,
                accessor: (data) => data.status, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'txnId', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transactionMonitor.table.headers.txnId')}</h5>,
                accessor: (data) => data.txnId, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'type', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transactionMonitor.table.headers.type')}</h5>,
                accessor: (data) => data.type, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'receiver', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transactionMonitor.table.headers.receiver')}</h5>,
                accessor: (data) => data.entity, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'creationDt', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transactionMonitor.table.headers.creationDate')}</h5>,
                accessor: (data) => data.creationDt, // Custom value accessors!
                Cell: (props) => <span className='text'>{parseDate(new Date(props.value))}</span>, // Custom value access
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'detail', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transactionMonitor.table.headers.detail')}</h5>,
                accessor: (data) => data.detail, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'action', // Required because our accessor is not a string
                Header: () => (
                    <div className='header-with-checkbox'>
                        <h5>{I18n.t('transactionMonitor.table.headers.action')}</h5>
                        <Checkbox
                            id='ALL'
                            checked={isCheckboxSelected('ALL')}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                ),
                accessor: (data) => data.buttons, // Custom value accessors!
                Cell: (props) => renderCheckBox(props.original),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
        ].filter((column) => (props.userIsGranter ? column : column.id !== 'grantAddresses'));
    };

    return (
        <div className='granting-monitor-screen'>
            <div className='header'>
                <span className='title'>{I18n.t('transactionMonitor.title')}</span>
                <SearchBar
                    textToSearch={textToSearch}
                    onSearch={(text) => {
                        setTextToSearch(text);
                        props.searchTransactions(text);
                    }}
                />
                <SelectMultiple
                    values={statusFilter}
                    onChange={handleStatusFilterChange}
                    className='rounded'
                    placeholder={I18n.t('transactionMonitor.statusLabel')}
                    options={[
                        {
                            value: 'Enviado',
                            label: I18n.t('transactionMonitor.table.status.sent'),
                        },
                        {
                            value: 'Recibido',
                            label: I18n.t('transactionMonitor.table.status.received'),
                        },
                        {
                            value: 'Fallo Envio',
                            label: I18n.t('transactionMonitor.table.status.error'),
                        },
                        {
                            value: 'all',
                            label: I18n.t('transactionMonitor.table.status.all'),
                        },
                    ]}
                />
                <Button
                    type='button'
                    className='primary small'
                    onClick={() => {
                        if (transactionsSelected.length > 0)
                            setShowConfirmGrantAddressesModal(true);
                    }}
                    text={I18n.t('transactionMonitor.reSend')}
                />
            </div>
            <div className='granting-monitor-table'>
                {props.loading ? (
                    <>
                        <div className='header' />
                        <div className='granting-monitor-table'>
                            <Loader />
                        </div>
                    </>
                ) : (
                    <ReactTable
                        className={
                            props.transactions.length > 0 ? 'nexus-table' : 'nexus-table empty'
                        }
                        PaginationComponent={(paginationProps) => (
                            <TablePagination
                                {...paginationProps}
                                total={props.total}
                                totalPages={props.totalPages}
                                pagedMessageTranslation='transactionMonitor.table.pagedMessage'
                                pagedMessageEmptyTranslation='transactionMonitor.table.pagedMessageEmpty'
                            />
                        )}
                        minRows={0}
                        pageSize={10}
                        data={props.transactions}
                        noDataText={I18n.t('transactionMonitor.table.noRows')}
                        // todo check if this prop is necessary
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        columns={getTableColumns()}
                    />
                )}
            </div>
            <ConfirmationModal
                show={showConfirmGrantAddressesModal}
                icon={<i className='icon-exclamation red' />}
                title={I18n.t('transactionMonitor.confirmModal.text')}
                noBody={true}
                cancel={() => setShowConfirmGrantAddressesModal(false)}
                submit={() => {
                    setShowConfirmGrantAddressesModal(false);
                    handleReSendSapButtonClick();
                }}
            />
            <TransactionsSuccessModal
                show={sendDataToSAPResults.allSuccessful}
                onClick={() => {
                    setSendDataToSAPResults(sendDataToSAPResultsInitialState);
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }}
                text={I18n.t('nexMonitor.successfulModal.transactionsSentToSAP')}
            />
            <TransactionsErrorModal
                show={
                    (sendDataToSAPResults.errorTotal > 0 || props.sendDataToSAPStatus.error) &&
                    !props.sendDataToSAPStatus.errorMessage
                }
                errors={sendDataToSAPResults.errors}
                isAValidationError={false}
                isASendDataToSAPError={true}
                close={() => {
                    setSendDataToSAPResults(sendDataToSAPResultsInitialState);
                    props.getTransactions();
                    // todo check how to clear the ui now, this might not work
                    props.clearUI();
                }}
                successfulTotal={sendDataToSAPResults.successfulTotal}
            />
        </div>
    );
};

export default TransactionMonitorScreen;
